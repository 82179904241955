<template>
    <div class="container">
        <h1>{{ $t('agentGroupsList.heading') }}</h1>

        <div class="bg-white p-4 shadow rounded-sm">
            <agent-groups-data-table
                :dataset="agentGroups"
                :loading="agentGroupsLoading"
                @add-agents="onAddAgents"
            />
        </div>

        <b-modal
            size="xl"
            v-model="showGroupsModal"
            :title="$t('agentGroupsList.modalTitle', [agentGroup?.name])"
            ok-only
            footer-class="d-flex justify-content-end flex-row-reverse"
        >
            <div>
                <p>
                    <b-btn
                        class="mr-3"
                        variant="primary"
                        size="sm"
                        @click.prevent="onAddAgentsToGroup(selectedAgents)"
                    >
                        <span class="iconify mr-2 text-white" data-icon="mdi:account-multiple-plus"></span>
                        {{ $t('agentGroupsDetail.addAgentsToGroup') }}
                        <b-badge variant="light">{{ selectedAgents.length }}</b-badge>
                    </b-btn>
                    <b-btn
                        variant="danger"
                        size="sm"
                        @click.prevent="onRemoveAgentsFromGroup(selectedAgents)"
                    >
                        <span class="iconify mr-2 text-white" data-icon="mdi:account-multiple-remove"></span>
                        {{ $t('agentGroupsDetail.removeAgentsFromGroup') }}
                        <b-badge variant="light">{{ selectedAgents.length }}</b-badge>
                    </b-btn>
                </p>

                <form-input-radio-group
                    v-model="filterGroups"
                    :label="$t('agentGroupsDetail.filterGroups')"
                    :options="filterOptions"
                    :stacked="false"
                />
                <agents-data-table
                    :filter-groups="filterGroups"
                    v-model="selectedAgents"
                    :agent-group="agentGroup || {}"
                    :dataset="agents"
                    :loading="agentsLoading || agentGroupLoading"
                    @add-to-group="onAddAgentsToGroup"
                    @remove-from-group="onRemoveAgentsFromGroup"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import AgentGroupsDataTable from './Components/AgentGroupsDataTable.vue'
import AgentsDataTable from './Components/AgentsDataTable.vue'

export default {
    name: 'AgentGroupsList',
    components: {
        AgentGroupsDataTable,
        AgentsDataTable
    },
    data () {
        return {
            agentGroups: [],
            agentGroupsLoading: false,
            showGroupsModal: false,
            agentGroup: null,
            agentGroupLoading: false,
            agents: [],
            agentsLoading: false,
            selectedAgents: [],
            filterGroups: 'all',
            agentGroupId: null
        }
    },
    computed: {
        filterOptions () {
            return [
                {
                    text: this.$t('agentGroupsDetail.filterGroupsAll'),
                    value: 'all'
                },
                {
                    text: this.$t('agentGroupsDetail.filterGroupsIsInGroup'),
                    value: 'isInGroup'
                },
                {
                    text: this.$t('agentGroupsDetail.filterGroupsIsNotInGroup'),
                    value: 'isNotInGroup'
                }
            ]
        }
    },
    async mounted () {
        await Promise.all([
            this.loadAgentGroups(),
            this.loadAgents()
        ])
    },
    methods: {
        async onRefresh () {
            await Promise.all([
                this.loadAgentGroups(),
                this.loadAgentGroup(),
                this.loadAgents()
            ])
        },
        async loadAgentGroups () {
            this.agentGroupsLoading = true
            try {
                const { data } = await this.$api.izdocs.getBrokerAgentGroups(this.$user.izdocsCompanyId)
                this.agentGroups = data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('agentGroupsList.loadAgentGroupsError'))
            } finally {
                this.$nextTick(() => {
                    this.agentGroupsLoading = false
                })
            }
        },
        async loadAgentGroup () {
            this.agentGroupLoading = true
            try {
                const { data } = await this.$api.izdocs.getBrokerAgentGroup(this.$user.izdocsCompanyId, this.agentGroupId)
                this.agentGroup = data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('agentGroupsDetail.loadAgentGroupError'))
            } finally {
                this.$nextTick(() => {
                    this.agentGroupLoading = false
                })
            }
        },
        async loadAgents () {
            this.agentsLoading = true
            try {
                const { data } = await this.$api.izdocs.getBrokerAgents(this.$user.izdocsCompanyId)
                this.agents = [...data]
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('agentGroupsDetail.agentsLoadError'))
            } finally {
                this.$nextTick(() => {
                    this.agentsLoading = false
                })
            }
        },
        async onRemoveAgentsFromGroup (agents) {
            try {
                await this.$api.izdocs.removeAgentsFromAgentGroup(
                    this.$user.izdocsCompanyId,
                    this.agentGroup.id,
                    { agentIds: agents.map(agent => agent.id) }
                )
            } catch (error) {
                console.error(error)
            } finally {
                await this.$nextTick()
                this.selectedAgents = []
                await this.onRefresh()
            }
        },
        async onAddAgentsToGroup (agents) {
            try {
                await this.$api.izdocs.addAgentsToAgentGroup(
                    this.$user.izdocsCompanyId,
                    this.agentGroup.id,
                    { agentIds: agents.map(agent => agent.id) }
                )
            } catch (error) {
                console.error(error)
            } finally {
                await this.$nextTick()
                this.selectedAgents = []
                await this.onRefresh()
            }
        },
        async onAddAgents (row) {
            this.agentGroupId = row.id
            await this.loadAgentGroup()
            this.showGroupsModal = true
        }
    }

}
</script>
<i18n>
{
    "cz": {
        "agentGroupsList": {
            "heading": "Skupiny agentů",
            "loadAgentGroupsError": "Nepodařilo se načíst skupiny agentů",
            "modalTitle": "Přiřazení agentů do skupiny: {0}"
        },
        "agentGroupsDetail": {
            "heading": "Detail skupiny",
            "loadAgentGroupError": "Nepodařilo se načíst skupinu agentů",
            "agentsLoadError": "Nepodařilo se načíst agenty",
            "addAgentsToGroup": "Přidat do skupiny",
            "removeAgentsFromGroup": "Odebrat ze skupiny",
            "goToAgentGroups": "Skupiny agentů",
            "agentsDataTableTitle": "Agenti zprostředkovatele",
            "filterGroupsAll": "Všechny",
            "filterGroupsIsInGroup": "Jsou ve skupině",
            "filterGroupsIsNotInGroup": "Nejsou ve skupině",
            "filterGroups": "Zobrazit agenty"
        }
    }
}
</i18n>
